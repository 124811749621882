<!--原材料批检设置-->
<template>
    <div class="batch-check-grade-set">
        <div class="wrapper-box">
            <table
                class="check-set-tb"
                border="0"
                align="center"
                cellpadding="0"
                cellspacing="0"
            >
                <tr>
                    <th>批检编号:</th>
                    <td><el-input :disabled="disable" v-model="testing.no" readonly></el-input></td>
                    <th>原材料分类:</th>
                    <td><el-input :disabled="disable" v-model="testing.type_name"></el-input></td>
                </tr>
                <tr>
                    <th>原材料子类:</th>
                    <td><el-input :disabled="disable" v-model="testing.child_type_name"></el-input></td>
                    <th>原材料规格:</th>
                    <td><el-input :disabled="disable" v-model="testing.spec_name"></el-input></td>
                </tr>
                <tr>
                    <th>供应商:</th>
                    <td><el-input :disabled="disable" v-model="testing.supplier_name"></el-input></td>
                    <th>货源地:</th>
                    <td><el-input :disabled="disable" v-model="testing.source_name"></el-input></td>
                </tr>
                <tr>
                    <th>批检时间:</th>
                    <td>
                        <el-date-picker
                            v-model="testing.check_date"
                            type="datetime"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </td>
                    <th>批检结果:</th>
                    <td>
                        <el-select
                            v-model="testing.grade_code"
                            @change="testingGradeChange"
                            v-if="details[0]"
                            :disabled="disable||isEnabledStandard"
                        >
                            <el-option
                                v-for="grade in gradeList"
                                :key="'rs'+grade.grade_code"
                                :label="grade.grade_name"
                                :value="grade.grade_code"
                            ></el-option>
                        </el-select>
                    </td>
                </tr>
                <tr>
                    <th>备注:</th>
                    <td colspan="3">
                        <el-input :disabled="disable" v-model="testing.remarks" placeholder="请输入"></el-input>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <!--        指标设置-->
                        <div class="check-item-tb">
                            <table>
                                <tr>
                                    <th style="width: 17%">
                                        批检指标
                                    </th>
                                    <th style="width: 17%">
                                        标准限值
                                    </th>
                                    <th style="width: 17%">
                                        试验结果
                                    </th>
                                    <th style="width: 17%">
                                        评定结论
                                    </th>
                                    <th>备注</th>
                                </tr>
                                <template v-for="(item,index) in details">
                                    <tr :key="index">
                                        <template>
                                            <td>
                                                {{ item.detail.item_name }}
                                            </td>
                                            <!-- eslint-disable vue/no-v-html -->
                                            <td v-html="item.detail.detail.replace(/\|/g,'<br>')">
                                            </td>
                                            <!-- eslint-disable vue/no-v-html -->
                                            <td>
                                                <el-input
                                                    :disabled="disable"
                                                    v-model="item.detail.actual_value"
                                                    placeholder="请输入试验结果"
                                                    @input="getCheckGrade(item)"
                                                >
                                                </el-input>
                                            </td>
                                            <td>
                                                {{ item.detail.grade_name }}
                                            </td>
                                            <td>
                                                <el-input :disabled="disable" v-model="item.detail.remarks" placeholder="请输入"></el-input>
                                            </td>
                                        </template>
                                    </tr>
                                </template>
                            </table>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="btns">
            <el-button
                class="save"
                type="primary"
                @click="saveData"
                v-if="!disable"
            >
                保存
            </el-button>
            <el-button class="close" @click="handleClose">
                关闭
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'batch-check-grade-set',
    data() {
        return {
            mbtid: '',
            // 基本信息
            testing: {},
            // 检测项目列表
            details: [],
            disable: false,

            // 品级列表
            gradeList: [{ grade_code: 'unqualified', grade_name: '不合格' }],
            // 指标检测结果
            itemResult: {},

            // 操作类型 1编辑 2查看
            operatetype: 1,
            // 是否启用标准
            isEnabledStandard: false,
        };
    },
    props: {
        extr: Object,
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.mbtid = this.extr.id;
            this.disable = Boolean(this.extr.IsView);
            if (this.disable) {
                this.operatetype = 2;
            }
            this.getTestInfo();
        },
        // 获取批检信息
        getTestInfo() {
            this.$axios.get(`/interfaceApi/procurement/material/batch/testing/grade/${this.mbtid}?operatetype=${this.operatetype}`)
                .then(data => {
                    this.testing = data.testing;
                    if (this.operatetype === 1) {
                        data.details.forEach(item => {
                            this.getCheckGrade(item);
                        });
                    }
                    this.details = data.details;
                    this.getGradeList();
                    this.getStationStandardEnabled();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取品级列表数据
        getGradeList() {
            const params = {
                type_dic_code: this.testing.type_dic_code,
                child_type_code: this.testing.child_type_code,
                child_type_dic_code: this.testing.child_type_dic_code,
                spec_code: this.testing.spec_code,
                spec_dic_code: this.testing.spec_dic_code,
            };
            this.$axios
                .post('/interfaceApi/procurement/quality/config/materialgrades', params)
                .then(res => {
                    this.gradeList = [...res, ...this.gradeList];
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存信息
        saveData() {
            const dataItems = this.details.map(item => item.detail);
            const dto = {
                testing: this.testing,
                details: dataItems,
            };
            this.$axios.post('/interfaceApi/procurement/material/batch/testing/grade/save', dto)
                .then(data => {
                    if (data) {
                        this.$message.success('操作成功');
                        window.updateTable();
                        this.handleClose();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        handleClose() {
            this.$parent.hide();
        },
        // 获取最终评级结果 有一个低级的最终结果即是最低级
        getFinalCheckGrade() {
            // 最终评审需要 每个项目都有值
            const haveNotInput = this.details.filter(item => !item.detail.grade_code);
            if (haveNotInput.length > 0) {
                return;
            }

            for (let i = 0; i < this.gradeList.length; i++) {
                const code = this.gradeList[i].grade_code;
                let checkFlag = true;
                // 筛选当前品级下包含的所有指标
                const tempItems = [];
                this.details.forEach(item => {
                    item.indexs.forEach(gradeItem => {
                        if (gradeItem.grade_code === code) {
                            tempItems.push(item);
                        }
                    });
                });
                // 判断 当前品级下所有指标检测结果是否都是当前品级
                for (const item of tempItems) {
                    if (item.detail.grade_code !== code) {
                        // 判断指标检测结果品级 是否时当前品级的 上层品级
                        let checkResultGradeindex = 0;
                        let curGradeIndex = 0;
                        const badResult = {
                            grade_code: 'unqualified',
                            grade_name: '不合格',
                        };
                        const curGradeList = [...item.indexs, badResult];
                        curGradeList.forEach((grade, index) => {
                            if (grade.grade_code === item.detail.grade_code ) {
                                checkResultGradeindex = index;
                            }
                            if (grade.grade_code === code ) {
                                curGradeIndex = index;
                            }
                        });
                        // 检测结果索引 > 当前品级 索引
                        if (checkResultGradeindex > curGradeIndex) {
                            // 按当前品级检测不通过
                            checkFlag = false;
                            break;
                        }
                    }
                }
                // 问题 检测有一项不合格，最终结果时不是不合格
                if (checkFlag) {
                    this.testing.grade_code = this.gradeList[i].grade_code;
                    this.testing.grade_name = this.gradeList[i].grade_name;
                    break;
                }
            }
        },
        // 获取检测项的 评级结果
        getCheckGrade(item) {
            if (item.detail.actual_value) {
                const stm = this.generateResult(item);
                if (stm) {
                    item.detail.grade_code = stm.grade_code;
                    item.detail.grade_name = stm.grade_name;
                } else {
                    item.detail.grade_code = 'unqualified';
                    item.detail.grade_name = '不合格';
                }
                this.getFinalCheckGrade();
            }
        },
        // 生成试验结果
        generateResult(item) {
            const val = item.detail.actual_value;
            if (val === '' || val === null) {
                return null;
            }
            // 按品级正序判断
            for (let i = 0; i < item.indexs.length; i++) {
                const stm = item.indexs[i];
                let expr = 'true';
                if (stm.value_max) {
                    expr += '&&val' + stm.symbol_max + stm.value_max;
                }
                if (stm.value_min) {
                    expr += '&&val' + stm.symbol_min + stm.value_min;
                }
                // eslint-disable-next-line no-eval
                const exprJs = eval(expr);
                if (exprJs) {
                    return stm;
                }
            }
            return null;
        },
        // 手动选择批检
        testingGradeChange(code) {
            for (const item of this.gradeList) {
                if (code === item.grade_code) {
                    this.testing.grade_name = item.grade_name;
                    break;
                }
            }
        },
        // 通过场站组织机构ID获取原材料是否启动规范快检
        async getStationStandardEnabled() {
            const url = `/interfaceApi/baseinfo/stationmanger/get_station_standard_batch_quality_enabled/${this.testing.station_id}`;
            const res = await this.$axios.get(url,);
            if (res) {
                this.isEnabledStandard = true;
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
.batch-check-grade-set{
    position relative;
    width 100%;
    height 100%;
    .wrapper-box{
        width 100%;
        height calc(100% - 0.9rem);
        overflow-y auto;
        table {
            width 100%;
            border-spacing: 0;
            border-collapse: collapse;
            th,td{
                font-size: 0.16rem;
                border: 1px solid #D7D7D7;
                padding 0.03rem;
            }
            th{
                text-align left;
                padding-left: 0.2rem;
                background: #F6F8FC;
                color: #002B94;
            }
            td{
                color #333;
                input,/deep/ input{
                    border 0;
                    font-size 0.16rem;
                }
                .el-input.is-disabled .el-input__inner {
                    background-color: #fff;
                    color #333;
                }
                /deep/ .el-input.is-disabled .el-input__inner {
                    background-color: #fff;
                    color #333;
                }
                >>> .el-select{
                    width 100%;
                }
            }
        }
        .check-set-tb{
            >tr{
                >th{
                    width 20%;
                }
                >td{
                    width 30%;
                }
            }
        }
        .check-item-tb{
            padding 0.06rem;
            td,th{
                text-align center
                padding 0.06rem 0;
            }
            .el-radio{
                display: block;
                text-align: left;
                padding: 0.03rem;
            }
            /deep/ .el-radio{
                display: block;
                text-align: left;
                padding: 0.03rem;
            }
            .el-input__inner{
                text-align center!important;
            }
            /deep/ .el-input__inner{
                text-align center!important;
            }
        }
    }
    .btns{
        position absolute
        left 0;
        bottom 0;
        padding-bottom 0.2rem;
        width 100%;
        display flex
        justify-content center;
        .el-button{
            width: 2.6rem;
            height: 0.5rem;
            font: 0.2rem '微软雅黑';
            margin: 0 0.15rem;
            border: none;
            cursor: pointer;
        }
        /deep/ .el-button{
            width: 2.6rem;
            height: 0.5rem;
            font: 0.2rem '微软雅黑';
            margin: 0 0.15rem;
            border: none;
            cursor: pointer;
        }
        .save{
            color: #fff;
            background: #1577d2;
        }
        .close{
            color: #1577d2;
            background: #fff;
            border: 1px solid #1577d2;
        }
    }
}
</style>
